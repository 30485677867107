import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fade from "react-reveal/Fade"

const LinkOrRaw = ({ href, children }) => {
  const onClick = React.useCallback(
    e => {
      if (typeof document !== "undefined" && document) {
        e.preventDefault()

        document.querySelector(href).scrollIntoView({
          behavior: "smooth",
        })
      }
    },
    [href]
  )

  if (href)
    return (
      <a href={href} onClick={onClick} className="card-anchor">
        {children}
      </a>
    )
  return children
}

export default function coloredHeader({
  title = "",
  subtitle = "",
  textColor = "black",
  themeColor = "#00c853",
  contentAlign = "left",
  content = [],
  icon = null,
  iconColor = "black",
  iconFS = "50px",
  itemsList = [],
  button = null,
  centerAll = false,
  iconSVG = null,
  iconLink = null,
}) {
  return (
    <div className="dxce-card" style={{ textAlign: centerAll ? "center" : "" }}>
      {(icon || iconSVG) && (
        <Fade top>
          <div className="dxce-card-icon">
            <LinkOrRaw href={iconLink}>
              {iconSVG || (
                <FontAwesomeIcon
                  icon={icon}
                  style={{
                    color: iconColor,
                    height: "50px",
                    wifth: "50px",
                    fontSize: iconFS,
                  }}
                />
              )}
            </LinkOrRaw>
          </div>
        </Fade>
      )}
      {title && (
        <Fade bottom>
          <div style={{ color: themeColor }} className="dxce-card-title">
            <LinkOrRaw href={iconLink}>{title}</LinkOrRaw>
          </div>
        </Fade>
      )}
      {button && <>{button}</>}
      <div className="dxce-card-content">
        {content.map(text => {
          return (
            <p key={text} style={{ textAlign: contentAlign, color: textColor }}>
              {text}
            </p>
          )
        })}
      </div>
      {subtitle && (
        <Fade bottom>
          <div className="dxce-card-subtitle">
            <LinkOrRaw href={iconLink}>{subtitle}</LinkOrRaw>
          </div>
        </Fade>
      )}
      {!!itemsList.length && (
        <ul className="dxce-card-list">
          {itemsList.map(liText => (
            <li key={liText}>{liText}</li>
          ))}
        </ul>
      )}
    </div>
  )
}
