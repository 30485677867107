import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HomeSafeCard = ({
  title = "HomeSafeCard",
  content = "HomeSafeCard",
  icon,
  height = 50,
  width = 50,
}) => {
  return (
    <div className="dxce-card dxce-card-home-safe">
      <div
        className="icon"
        style={{
          height,
          width,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            height: height,
            width: width,
          }}
        />
      </div>
      <h2 className="dxce-card-title">{title}</h2>
      <p>{content}</p>
    </div>
  )
}

export default HomeSafeCard
