/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Fade from "react-reveal/Fade"
import { Link } from "../components/CustomLinks"
import Button from "@material-ui/core/Button"
import {
  faChartLine,
  faHeartbeat,
  faGlobeAmericas,
  faTimes,
  faHandHoldingHeart,
  faHandshake,
  faGear,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, Container } from "@material-ui/core"
import Separator from "../components/separator"
import Layout from "../components/layout"
import ColoredHeader from "../components/coloredHeader"
import AllMightyCard from "../components/allMightyCard"
import Toggle from "../components/toggle"
import FloatingSearch from "../components/floatingSearch"
import culture_balloons from "../../static/background_images/culture_balloons.png"
import Image_A from "../../static/images/Image_A.png"
import exploration from "../../static/images/exploration.png"
import operations from "../../static/images/operations.png"
import IT from "../../static/images/IT.png"
import culture_it_art from "../../static/images/culture_it_art.png"
import TWP2024_HOU from "../../static/images/2024-houston.png"
import TWP2024_OKC from "../../static/images/2024-okc.png"
import TWP2024_SA from "../../static/images/2024-san-antonio.png"
import TWP2024_AQB from "../../static/images/2024-abq.png"
import TWP2024_DEN from "../../static/images/2024-denver-single.png"
import TWP2024_GREATPLAINS from "../../static/images/2024-great-plains.jpeg"
import TWP2024_USA from "../../static/images/2024-usa-today.jpeg"
import energage from "../../static/images/energage-2021.png"
import culturalBackground from "../../static/background_images/cultural-bg.png"
import EveryOneGoesHomeSafe from "../../static/images/EveryOne-Goes-Home-Safe.png"
import HomeSafeCard from "../pages/culture/HomeSafeCard"

const EOG_Value_Proposition_Highlights =
  "/documents/2023_Compensation_and_Benefits_Highlights.pdf"

export default function Culture(props) {
  const themeColor = "#ff6d00"
  const [value, setValue] = React.useState(0)

  const [modalState, setModalState] = useState({
    open: false,
  })

  const onClose = () => {
    setModalState({ open: false, paragraphs: [], name: "", title: "" })
  }

  return (
    <Layout>
      <Helmet>
        <title>Culture – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="CULTURE"
        header="Our Sustainable Competitive Advantage"
        overlayColor1="#ffaa00"
        overlayColor2={themeColor}
        lineColor="#ffc146"
        bgImageSRC={culture_balloons}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <section className="page culture-page tabs-section desktop-only">
        <div className="max-width-container">
          <div className="ts-first">
            <img
              className={`tab-clicker tab-clicker-1 ${
                value === 0 ? "active" : ""
              }`}
              onClick={() => {
                setValue(0)
              }}
              onKeyPress={() => {
                setValue(0)
              }}
              src={Image_A}
              alt="Culture"
              //
              role="button"
            />
            <img
              className={`tab-clicker tab-clicker-2 ${
                value === 1 ? "active" : ""
              }`}
              onClick={() => {
                setValue(1)
              }}
              onKeyPress={() => {
                setValue(1)
              }}
              src={exploration}
              alt="exploration"
              role="button"
            />
            <img
              className={`tab-clicker tab-clicker-3 ${
                value === 2 ? "active" : ""
              }`}
              onClick={() => {
                setValue(2)
              }}
              onKeyPress={() => {
                setValue(2)
              }}
              src={operations}
              alt="operations"
              role="button"
            />
            <img
              className={`tab-clicker tab-clicker-4 ${
                value === 3 ? "active" : ""
              }`}
              onClick={() => {
                setValue(3)
              }}
              onKeyPress={() => {
                setValue(3)
              }}
              src={IT}
              alt="information technology"
              role="button"
            />
          </div>
          <TabPanel className="culture-tabpanel" value={value} index={0}>
            <Fade>
              <div className="ts-second">
                <h2>
                  <span className="tab-title">Business Person First</span>
                </h2>
                <p>
                  <span>
                    No matter your role at EOG, you’re a business person first.
                    And since we’re all shareholders, we think like owners.
                    &nbsp;We’re entrepreneurs and innovators, not bureaucrats.
                    Our decentralized approach means decisions are made where
                    the work is done by collaborative, multi-disciplinary teams.
                    By remaining humble and intellectually honest, we’ll
                    continue to improve and stay competitive. Our culture drives
                    EOG’s success.
                  </span>
                </p>
              </div>
            </Fade>
          </TabPanel>

          <TabPanel className="culture-tabpanel" value={value} index={1}>
            <Fade>
              <div className="ts-second">
                <h2>
                  <span className="tab-title">Prospect-Generating Machine</span>
                </h2>
                <p>
                  <span>
                    We drill the most prolific horizontal oil and gas wells in
                    the U.S. and that didn’t happen overnight. We find the best
                    rock, in the best plays, at low cost. We are a
                    prospect-generating machine with an early-mover advantage.
                    &nbsp;Instead of growing by acquisition or by being a fast
                    follower, we prefer to grow organically which, in our
                    opinion, is the most sustainable business model. When you
                    think EOG, think “high-return, organic growth company.”
                  </span>
                </p>
              </div>
            </Fade>
          </TabPanel>
          <TabPanel className="culture-tabpanel" value={value} index={2}>
            <Fade>
              <div className="ts-second">
                <h2>
                  <span className="tab-title">Pleased but not Satisfied</span>
                </h2>
                <p>
                  <span>
                    For every technology, engineering or logistics breakthrough
                    we make at EOG, our motto remains “pleased, but not
                    satisfied.” We are constantly refining our industry-leading
                    drilling and completion technology. We continue to reduce
                    drilling times and improve the precision targeting of our
                    horizontal wells. We challenge ourselves to think like
                    entrepreneurs which leads to cost-saving initiatives such as
                    self-sourced materials and services. The result: EOG is low
                    cost and can thrive in any commodity price cycle.{" "}
                  </span>
                </p>
              </div>
            </Fade>
          </TabPanel>
          <TabPanel className="culture-tabpanel" value={value} index={3}>
            <Fade>
              <div className="ts-second">
                <img
                  src={culture_it_art}
                  className="culture-img"
                  alt="culture it art"
                />
                <h2 className="tab-title">
                  Owning Data from Creation to Delivery ™&nbsp;
                </h2>
                <p>
                  Data is at the heart of EOG’s sustainable and ever increasing
                  technological competitive advantage. EOG’s real-time data
                  capture and our ability to transport that data to decision
                  makers is enabling us to operate at the speed of thought. Our
                  massive proprietary integrated data warehouses and predictive
                  analytics are unraveling mysteries and simplifying the complex
                  before our eyes daily. And, more critically, our 100+
                  proprietary, in-house developed, mobile and desktop apps give
                  everyone at EOG a virtual control room in their pocket.
                </p>
              </div>
            </Fade>
          </TabPanel>
        </div>
      </section>
      <section className="page culture-page tabs-section mobile-only">
        <Toggle className="first" title="Culture" noIcon>
          <div className="ts-second">
            <h2>
              <span className="tab-title">Business Person First</span>
            </h2>
            <p>
              <span>
                No matter your role at EOG, you’re a business person first. And
                since we’re all shareholders, we think like owners. &nbsp;We’re
                entrepreneurs and innovators, not bureaucrats. Our decentralized
                approach means decisions are made where the work is done by
                collaborative, multi-disciplinary teams. By remaining humble and
                intellectually honest, we’ll continue to improve and stay
                competitive. Our culture drives EOG’s success.
              </span>
            </p>
          </div>
        </Toggle>
        <Toggle title="Exploration" noIcon>
          <div className="ts-second">
            <h2>
              <span className="tab-title">Prospect-Generating Machine</span>
            </h2>
            <p>
              <span>
                We drill the most prolific horizontal oil and gas wells in the
                U.S. and that didn’t happen overnight. We find the best rock, in
                the best plays, at low cost. We are a prospect-generating
                machine with an early-mover advantage. &nbsp;Instead of growing
                by acquisition or by being a fast follower, we prefer to grow
                organically which, in our opinion, is the most sustainable
                business model. When you think EOG, think “high-return, organic
                growth company.”
              </span>
            </p>
          </div>
        </Toggle>
        <Toggle title="Operations" noIcon>
          <div className="ts-second">
            <h2>
              <span className="tab-title">Pleased but not Satisfied</span>
            </h2>
            <p>
              <span>
                For every technology, engineering or logistics breakthrough we
                make at EOG, our motto remains “pleased, but not satisfied.” We
                are constantly refining our industry-leading drilling and
                completion technology. We continue to reduce drilling times and
                improve the precision targeting of our horizontal wells. We
                challenge ourselves to think like entrepreneurs which leads to
                cost-saving initiatives such as self-sourced materials and
                services. The result: EOG is low cost and can thrive in any
                commodity price cycle.{" "}
              </span>
            </p>
          </div>
        </Toggle>
        <Toggle title="Information Technology" noIcon>
          <div className="ts-second">
            <p style={{ width: "100%" }}>
              <img
                src={culture_it_art}
                className="culture-img"
                alt="culture it art"
              />
            </p>
            <h2>
              <span className="tab-title">
                Owning Data from Creation to Delivery ™&nbsp;
              </span>
            </h2>
            <p>
              <span>
                Data is at the heart of EOG’s sustainable and ever increasing
                technological competitive advantage. EOG’s real-time data
                capture and our ability to transport that data to decision
                makers is enabling us to operate at the speed of thought. Our
                massive proprietary integrated data warehouses and predictive
                analytics are unraveling mysteries and simplifying the complex
                before our eyes daily. And, more critically, our 100+
                proprietary, in-house developed, mobile and desktop apps give
                everyone at EOG a virtual control room in their pocket.
              </span>
            </p>
          </div>
        </Toggle>
      </section>
      <section className="page culture-page halved-section">
        <div className="max-width-container">
          <div className="culture-halved-card first">
            <h4>Let's Get to Work</h4>
            <p>
              EOG’s success is driven by our diverse and inclusive workforce. We
              are always looking for innovative and talented individuals to join
              our team. Explore our open opportunities.
            </p>
            <a
              href="https://careers.eogresources.com/process_jobsearch.asp"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="culture-button-lg">EOG Career Search</Button>
            </a>
          </div>
          <div className="culture-halved-card second">
            <h4>College Recruiting</h4>
            <p>
              Curious about the EOG Intern Experience? We are actively
              recruiting unconventional thinkers for intern and full-time
              opportunities. Learn more about our summer internships.
            </p>
            <Link to="/culture/recruiting-events/">
              <Button className="culture-button-lg">
                EOG Intern Experience
              </Button>
            </Link>
          </div>
        </div>
      </section>

      <section className="culture-page">
        <div
          className="culture-energage-description"
          style={{
            backgroundImage: `url("${culturalBackground}")`,
            backgroundSize: "cover",
          }}
        >
          <div className="max-width-container culture-page success-section">
            <h2 className="subheader">Success Beyond Salary</h2>
            <Separator width="60%" />

            <div className="cols">
              <div className="col">
                We know that our success comes from our intelligent, innovative,
                engaged, hard-working employees. Our employees are champions of
                the EOG culture, leaders who encourage each other to succeed and
                do the right thing. That’s why we do more than just provide
                competitive salaries and annual bonus opportunities.
              </div>
              <div className="col">
                We have a holistic value proposition. We also want to help our
                employees build wealth, stay healthy, be protected when life
                happens, meet and exceed their personal and career goals, and
                maintain a good work/life balance. We believe that when everyone
                contributes, everyone wins.
              </div>
            </div>
            <div className="dxce-card-holder">
              <AllMightyCard
                icon={faChartLine}
                iconColor={"black"}
                title="Build for the Future"
                themeColor={themeColor}
                contentAlign="left"
                content={[
                  "Helping our employees plan and save for their future is a key part of the EOG plan. That’s why we offer many different ways for employees to build wealth and be rewarded for their hard work, including:",
                ]}
                itemsList={[
                  "Savings and Retirement Plan with Matching and Retirement Contributions",
                  "Annual Stock Grants to employee shareholders",
                  "Employee Stock Purchase Plan",
                  "Chairman’s Award Program",
                  "Personal Best Awards",
                ]}
              />
              <AllMightyCard
                icon={faHeartbeat}
                iconColor={"black"}
                title="An Emphasis on Wellness"
                themeColor={themeColor}
                contentAlign="left"
                content={[
                  "When you’re healthier, you’re happier. We provide programs to help our employees stay healthy and be protected in the case of certain life events, including:",
                ]}
                itemsList={[
                  "Best-in-Class Medical, Prescription Drug, Vision, and Dental coverage",
                  "Health Savings Account with company contributions",
                  "Wellness Program",
                  "Employee Assistance Program",
                  "Short Term and Long Term Disability protection",
                  "Employee Life and Accidental Death & Dismemberment Insurance",
                ]}
              />
              <AllMightyCard
                icon={faGlobeAmericas}
                iconColor={"black"}
                title="Beyond the Workplace"
                themeColor={themeColor}
                contentAlign="left"
                content={[
                  "We know that our employees are also people with lives outside work. We provide time and programs to help employees fulfill their sense of purpose. That’s why we offer:",
                ]}
                itemsList={[
                  "4 – 6 weeks of Vacation",
                  "11 paid Holidays",
                  "Flexible work hours",
                  "Sick Pay",
                  "Family Care Pay",
                  "Adoption Assistance Program",
                  "Volunteer Time",
                  "Matching Gifts Program",
                  "Casual Dress",
                  "Training, Professional/Leadership Development, and Mentorship Programs",
                  "Tuition Reimbursement Program",
                ]}
              />
            </div>
            <div
              style={{
                textAlign: "center",
                display: "float",
                floatDirection: "column",
              }}
            >
              <a
                href={EOG_Value_Proposition_Highlights}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="culture-button-lg orange">
                  Download Our Value Proposition Highlights
                </Button>
              </a>

              <a
                href={"https://www.bcbstx.com/asomrf?EIN=470684736"}
                target="_blank"
                rel="noreferrer"
                className={"orange"}
                style={{ display: "block" }}
              >
                Transparency in Coverage
              </a>
            </div>
          </div>

          <div className=" culture-page success-section">
            <div className="max-width-container">
              <div className="best-place-to-work-container">
                <div className="best-place-to-work-usa">
                  <img src={TWP2024_USA} alt="gptw 2024 USA" />
                </div>
                <div className="best-place-to-work-others">
                  <div className="max-width-container best-place-to-work">
                    <img src={TWP2024_HOU} alt="gptw 2024 Houston" />
                    <img src={TWP2024_SA} alt="gptw 2024 San Antonio" />
                  </div>
                  <div className="max-width-container best-place-to-work">
                    <img src={TWP2024_OKC} alt="gptw 2024 OKC" />
                    <img src={TWP2024_DEN} alt="gptw 2024 Denver" />
                  </div>
                  <div className="max-width-container best-place-to-work">
                    <img src={TWP2024_AQB} alt="gptw 2024 ABQ" />
                    <img
                      src={TWP2024_GREATPLAINS}
                      alt="gptw 2024 GREAT PLAINS"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="culture-energage-description"
          style={{
            backgroundImage: `url("${culturalBackground}")`,
            backgroundSize: "cover",
            paddingBottom: 70,
          }}
        >
          <div className="max-width-container culture-page success-section eog-home-safe last-page">
            <h2 className="subheader" style={{ marginBottom: 20 }}>
              EOG Home Safe
              <br />
              <strong>E</strong>very<strong>O</strong>ne
              <strong>G</strong>oes Home Safe
            </h2>

            <Separator width="60%" />
            <br />
            <br />

            <div className="cols home-safe-content">
              <div className="eog-home-safe-logo-container">
                <img
                  src={EveryOneGoesHomeSafe}
                  alt="EveryOne Goes Home Safe"
                  className="eog-home-safe-logo"
                />
              </div>
              <div>
                <p>
                  EOG has a long tradition of being committed to safe operations
                  and environmental stewardship. The <strong>E</strong>very
                  <strong>O</strong>ne<strong>G</strong>oes Home Safe, or EOG
                  Home Safe, program further reinforces our company's safety
                  culture. This program is comprised of three principles that
                  communicate what safety means and how it is viewed and
                  practiced at EOG. At EOG, we believe safety is:
                </p>

                <div className="cols inner">
                  <HomeSafeCard
                    icon={faHandHoldingHeart}
                    title="A Value"
                    content="Values are consistent, unlike priorities that may change."
                  />
                  <HomeSafeCard
                    title="Personal"
                    icon={faHandshake}
                    content="Know and care for your team."
                    width={63}
                  />
                  <HomeSafeCard
                    icon={faGear}
                    title="Continously Improving"
                    content="Incremental changes add up to significant results."
                  />
                </div>
                <p>
                  When we approach safety with these principles in mind, each
                  person takes responsibility for their own safety and the
                  safety of others to achieve our vision that <strong>E</strong>
                  very
                  <strong>O</strong>ne<strong>G</strong>oes Home Safe every day.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={modalState.open}
        onClose={onClose}
        closeAfterTransition
        onEscapeKeyDown={() => onClose()}
        onBackdropClick={() => onClose()}
      >
        <div
          className="g-modal-inner"
          onClick={e => {
            e.stopPropagation()
            onClose()
          }}
          onKeyPress={e => {
            console.log({ e })
            e.stopPropagation()
            if (e.key === "escape") {
              onClose()
            }
          }}
          role="button"
          tabIndex={0}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Container
            style={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            <FontAwesomeIcon
              className="close-control"
              icon={faTimes}
              onClick={onClose}
              onKeyPress={onClose}
              style={{ float: "right" }}
            />
            <a href={energage}>
              <img
                src={energage}
                alt="great place to work chart"
                style={{ maxWidth: "100%" }}
              />
            </a>
          </Container>
        </div>
      </Modal>
    </Layout>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
